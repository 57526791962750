import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import ThumbSVG from '../assets/icons/cursorthumb.svg'

const Thumb = props => {
  return <ThumbSVG {...props} style={{ display: 'block' }} />
}

const CursorThumb = styled(Thumb)`
  margin-left: 9.3px;
  width: 100px;
  height: 100px;
  position: fixed;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 2;

  path {
    stroke: var(--screen-500);
    fill: white;
  }
`

function AnimatedThumbCursor({ hovered }) {
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(false)

  useEffect(() => {
    addEventListeners()
    return () => removeEventListeners()
  })

  const addEventListeners = () => {
    document.addEventListener('mousemove', onMouseMove)
  }

  const removeEventListeners = () => {
    document.removeEventListener('mousemove', onMouseMove)
  }

  const onMouseMove = e => {
    const wrapper = document.getElementById('pills')
    const cursor = document.getElementById('thumb')

    const wrapperStartY = wrapper.getBoundingClientRect().y
    const wrapperEndY = wrapper.getBoundingClientRect().height + wrapperStartY
    const windowWidth = window.innerWidth

    setPosition({ x: e.clientX, y: e.clientY })
    cursor.style.top = `${position.y}px`
    cursor.style.left = `${position.x}px`

    //calculate rotation of the thumb
    setRotation(Math.round((position.x / windowWidth) * 60 + -45))

    cursor.style.transform = hovered
      ? `translate(-50%, -50%) rotate(${rotation}deg) scale(2)`
      : `translate(-50%, -50%) rotate(${rotation}deg)`

    //don't display the cursor if outside of the sliding pills container
    if (position.y < wrapperStartY || position.y > wrapperEndY) {
      cursor.style.position = 'absolute'
      cursor.style.display = 'none'
    } else {
      cursor.style.position = 'fixed'
      cursor.style.display = 'block'
    }
  }

  return <CursorThumb id="thumb"></CursorThumb>
}

export default AnimatedThumbCursor
