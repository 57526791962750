import React, { useState } from 'react'
import styled from '@emotion/styled'
import mq from '../styles/mediaQueries'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'

import 'swiper/css/bundle'
import 'swiper/css'

import { Headline5, Subheading2, Body1 } from '../components/Typography'

import LeftSVG from '../assets/icons/left.svg'
import RightSVG from '../assets/icons/right.svg'

SwiperCore.use([Navigation])

const cardText = [
  [
    'Transparent',
    'Communication and openness facilitate close collaboration. We’re here for the conversations and brainstorms that fuel our best work.',
    'Daily stand-ups, retros with clients and stakeholders, shared slack channels',
    'How we do it',
  ],
  [
    'Quality',
    'We deliver value efficiently. Our techniques are solid so we can confidently rely on them as credible solutions.',
    'Automated testing, code reviews, pair programming, continuous integration and deployment',
    'How we do it',
  ],
  [
    'Teamwork',
    'Working together supercharges project success and our ability to improve skills within the Super Good team and larger community.',
    'Cross-project code review, cross-project code pair programming, internal presentations and info sharing',
    'How we do it',
  ],
  [
    'Make Responsible Calls',
    'We own our work and the projects we work on, making confident decisions with airtight solutions over risky speculation.',
    'Feature flags for clients to test out functionality in production before roll-out, features delivered incrementally to show progress',
    'How we do it',
  ],
  [
    'Professionalism',
    'We have high standards for everything we do. We deliver value in every step of our process from daily interactions to project solutions.',
    'Work documentation, systems architecture simplification, no new "hot" technologies until they are proven',
    'How we do it',
  ],
]

const Left = props => {
  return <LeftSVG {...props} style={{ display: 'block' }} />
}

const Right = props => {
  return <RightSVG {...props} style={{ display: 'block' }} />
}

const SlideCard = styled.div`
  height: 480px;
  display: flex;
  flex-direction: column;
  background: ${props =>
    props.isActive
      ? props.hover
        ? 'var(--screen-400)'
        : 'var(--screen-500)'
      : 'transparent'};
  transition: background-color 100ms ease;
  border: ${props =>
    props.isActive ? 'unset' : '1px solid var(--terminal-100)'};
  box-shadow: ${props =>
    props.isActive
      ? '0px 60px 80px rgba(0, 0, 0, 0.2), 0px 24px 36px rgba(0, 0, 0, 0.16), 0px 4px 8px rgba(0, 0, 0, 0.12)'
      : 'unset'};
  margin: 80px auto 200px auto;
  ${mq({
    maxWidth: ['80vw', '44vw', '40vw', '382px', '382px'],
  })};
`

const SlideTitle = styled.div`
  ${Headline5};
  ${mq({
    padding: ['32px', '32px', '48px', '48px', '48px'],
  })};
  color: ${props =>
    props.isActive ? 'var(--terminal-0)' : 'var(--terminal-200)'};
  font-weight: 500;
  display: flex;
  justify-content: center;
`

const SlideBody = styled.div`
  ${Subheading2};
  ${mq({
    padding: ['0 32px', '0 32px', '0 48px', '0 48px', '0 48px'],
  })};
  color: ${props =>
    props.isActive ? 'var(--screen-200)' : 'var(--terminal-200)'};
  display: flex;
  justify-content: center;
  text-align: center;

  ${mq({
    fontSize: ['28px', '22px', '24px', '24px', '24px'],
  })};
`

const SlideBodyHover = styled.div`
  ${Body1};
  ${mq({
    padding: ['0 32px', '0 32px', '0 48px', '0 48px', '0 48px'],
  })};
  color: var(--screen-50);
  display: flex;
  justify-content: center;
  text-align: center;
`

const SlidePill = styled.div`
  ${Body1};
  font-weight: bold;
  color: var(--terminal-0);
  display: flex;
  justify-content: center;
  background-color: ${props =>
    props.hover && props.isActive
      ? 'var(--screen-500)'
      : props.isActive
      ? ' var(--screen-400)'
      : 'var(--terminal-100)'};
  border-radius: 24px;
  padding: 8px 24px;
  margin-top: auto;
  max-width: 156px;
  align-self: center;
  transition: background-color 100ms ease;
  cursor: pointer;
  ${mq({
    marginBottom: ['32px', '32px', '48px', '48px', '48px'],
  })};
`

const Prev = styled(Left)`
  position: absolute;
  width: 30px;
  height: 44px;
  z-index: 3;
  border: none;
  path {
    stroke: var(--terminal-400);
  }

  ${mq({
    left: ['10vw', '20vw', '24vw', '26vw', '30vw'],
    top: [
      '85%',
      'calc(50% - 22px)',
      'calc(50% - 22px)',
      'calc(50% - 22px)',
      'calc(50% - 22px)',
    ],
  })};
`

const Next = styled(Right)`
  position: absolute;
  width: 30px;
  height: 44px;
  z-index: 3;
  border: none;
  path {
    stroke: var(--terminal-400);
  }

  ${mq({
    right: ['10vw', '20vw', '24vw', '26vw', '30vw'],
    top: [
      '85%',
      'calc(50% - 22px)',
      'calc(50% - 22px)',
      'calc(50% - 22px)',
      'calc(50% - 22px)',
    ],
  })};
`

function SwiperCard({
  isActive,
  setHover,
  hover,
  slideTitle,
  slideBody,
  slideBodyHover,
  slidePill,
}) {
  return (
    <SlideCard isActive={isActive} hover={hover}>
      <SlideTitle isActive={isActive}>{slideTitle}</SlideTitle>
      {hover && isActive ? (
        <SlideBodyHover>{slideBodyHover}</SlideBodyHover>
      ) : (
        <SlideBody isActive={isActive}>{slideBody}</SlideBody>
      )}
      <SlidePill
        hover={hover}
        isActive={isActive}
        onMouseEnter={() => (isActive ? setHover(true) : null)}
        onMouseLeave={() => setHover(false)}
      >
        {slidePill}
      </SlidePill>
    </SlideCard>
  )
}

function AboutSwiper() {
  const [hover, setHover] = useState(false)

  return (
    <Swiper
      centeredSlides={true}
      loop={true}
      slidesPerView={1.2}
      speed={600}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }}
      breakpoints={{
        600: {
          slidesPerView: 1.7,
        },
        840: {
          slidesPerView: 2,
        },
        1025: {
          slidesPerView: 2.3,
        },
      }}
    >
      {cardText.map((text, index) => (
        <SwiperSlide key={index}>
          {({ isActive }) => (
            <SwiperCard
              isActive={isActive}
              hover={hover}
              setHover={setHover}
              slideTitle={text[0]}
              slideBody={text[1]}
              slideBodyHover={text[2]}
              slidePill={text[3]}
            />
          )}
        </SwiperSlide>
      ))}
      <Prev className="swiper-button-prev"></Prev>
      <Next className="swiper-button-next"></Next>
    </Swiper>
  )
}

export default AboutSwiper
