import React, { useEffect, useRef } from 'react'
import Matter, {
  Engine,
  Render,
  Bodies,
  World,
  Mouse,
  MouseConstraint,
} from 'matter-js'

const imageScales = [
  [128, 0.5],
  [96, 0.375],
  [96, 0.375],
  [96, 0.375],
  [64, 0.25],
  [64, 0.25],
  [64, 0.25],
  [64, 0.25],
  [64, 0.25],
]

function MatterComponent(props) {
  const scene = useRef()
  const engine = useRef(Engine.create())

  useEffect(() => {
    const cw = document.documentElement.clientWidth
    let desktop = cw > 512

    const ch = desktop
      ? props.fullheight
        ? cw * 0.5625
        : (cw * 0.5625) / 1.5
      : props.fullheight
      ? cw * 1.5
      : cw

    const render = Render.create({
      element: scene.current,
      engine: engine.current,
      options: {
        width: cw,
        height: ch,
        wireframes: false,
        background: 'transparent',
      },
    })

    // Add the walls.
    World.add(engine.current.world, [
      // Top wall:
      Bodies.rectangle(cw / 2, -499, cw + 2000, 1000, {
        isStatic: true,
        render: { fillStyle: 'transparent' },
      }),

      // Left wall:
      Bodies.rectangle(-499, ch / 2, 1000, ch, {
        isStatic: true,
        render: { fillStyle: 'transparent' },
      }),

      // Bottom wall:
      Bodies.rectangle(cw / 2, ch + 499, cw + 2000, 1000, {
        isStatic: true,
        render: { fillStyle: 'transparent' },
      }),

      // Right wall:
      Bodies.rectangle(cw + 499, ch / 2, 1000, ch, {
        isStatic: true,
        render: { fillStyle: 'transparent' },
      }),
    ])

    let scale

    if (desktop) {
      scale = cw / 1280
    } else {
      scale = cw / 900
    }

    props.images
      .sort((a, b) => 0.5 - Math.random())
      .forEach((image, index) => {
        const position = imageScales[index % imageScales.length]

        const ball = Bodies.circle(
          Math.floor(Math.random() * cw),
          0,
          position[0] * scale,
          {
            density: 0.1,
            frictionAir: 0,
            restitution: 0.9,
            friction: 0.6,
            render: {
              sprite: {
                texture: image,
                xScale: position[1] * scale,
                yScale: position[1] * scale,
              },
            },
          }
        )
        World.add(engine.current.world, [ball])
      })

    // add mouse control
    const mouse = Mouse.create(render.canvas),
      mouseConstraint = MouseConstraint.create(engine.current, {
        mouse: mouse,
        constraint: {
          stiffness: 0.1,
          render: {
            visible: false,
          },
        },
      })

    mouseConstraint.mouse.element.removeEventListener(
      'mousewheel',
      mouseConstraint.mouse.mousewheel
    )
    mouseConstraint.mouse.element.removeEventListener(
      'DOMMouseScroll',
      mouseConstraint.mouse.mousewheel
    )

    World.add(engine.current.world, mouseConstraint)
    Matter.Runner.run(engine.current)
    Render.run(render)

    return () => {
      Render.stop(render)
      World.clear(engine.current.world)
      Engine.clear(engine.current)
      render.canvas.remove()
      render.canvas = null
      render.context = null
      render.textures = {}
    }
  }, [])

  return (
    <div>
      <div ref={scene} style={{ width: '100%', height: '100%' }} />
    </div>
  )
}

export default MatterComponent
