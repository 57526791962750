import React, { useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from '@emotion/styled'
import mq from '../styles/mediaQueries'
import { Link } from 'gatsby'

import HeroPageTemplate from '../templates/hero-page'
import Seo from '../components/seo'
import ContactForm from '../components/ContactForm'

import { Headline2, Headline4, Body2, Body1 } from '../components/Typography'

import AboutCarousel from '../components/AboutCarousel'
import MatterComponent from '../components/Matter'
import AboutSlidingPills from '../components/AboutSlidingPills'
import Layout from '../components/Layout'

const teamImages = [
  '/images/adam.png',
  '/images/alistair.png',
  '/images/amy.png',
  '/images/andrew.png',
  '/images/benjamin.png',
  '/images/chris.png',
  '/images/jared.png',
  '/images/kendra.png',
  '/images/nick.png',
  '/images/noah.png',
  '/images/senem.png',
  '/images/sofia.png',
]

const BackgroundWrapper = styled.div`
  background: #e5e5e5;
`

const ContactFormWrapper = styled.div`
  ${mq({
    gridColumnStart: ['1', '1', '2', '2', '2'],
    gridColumnEnd: ['5', '9', '12', '12', '12'],
  })};
`

const TextColumnWrapper = styled.div`
  ${mq({
    gridColumnStart: ['1', '1', '1', '3', '3'],
    gridColumnEnd: ['5', '9', '13', '11', '11'],
    columnCount: ['1', '3', '3', '3', '3'],
    marginBottom: ['160px', '160px', '200px', '240px', '240px'],
  })};
  column-rule-style: solid;
  column-rule-width: 1px;
  column-rule-color: var(--terminal-100);
  column-gap: 72px;
`

const ColumnTitle = styled.div`
  ${Headline4};
  color: var(--terminal-500);
  margin-bottom: 8px;
`

const ColumnBody = styled.div`
  ${Body2};
  color: var(--terminal-400);
  break-inside: avoid-column;
  column-span: 1;
`

const ColumnHorizontalLine = styled.hr`
  border: 1px solid var(--terminal-100);
  margin: 24px 0;
  ${mq({
    display: ['block', 'none', 'none', 'none', 'none'],
  })};
`

const MatterPlaceholder = styled.div`
  background: #e5e5e5;
  height: 100vh;
  width: 100vw;
`

const Spacer = styled.div`
  ${mq({
    height: ['160px', '160px', '200px', '200px', '200px'],
  })};
`

const Title = styled.h2`
  ${Headline2};
  color: var(--terminal-500);
  margin-block-start: 0;
  margin-block-end: 0;
  text-align: center;
`

const TitleLink = styled.a`
  ${Body1};
  color: var(--firewall-500);
  text-decoration: none;
  font-weight: bold;
  display: flex;
  justify-content: center;
`

const TitleGatsbyLink = styled(Link)`
  ${Body1};
  color: var(--firewall-500);
  text-decoration: none;
  font-weight: bold;
  display: flex;
  justify-content: center;
`

function About() {
  const [windowResizing, setWindowResizing] = useState(false)
  const [contactFormRef, contactFormInView] = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
  })
  const pageTitle = 'About'

  const firstLine = 'Hi, we’re Super Good.'
  const secondLine =
    'We’re a tightly knit team of ecommerce software experts who build on the Solidus platform.'

  useEffect(() => {
    let timeout
    const cachedWidth = window.innerWidth

    const handleResize = () => {
      clearTimeout(timeout)

      if (cachedWidth !== window.innerWidth) {
        setWindowResizing(true)
      }

      timeout = setTimeout(() => {
        setWindowResizing(false)
      }, 200)
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <BackgroundWrapper>
      <HeroPageTemplate
        theme={'day'}
        firstLine={firstLine}
        secondLine={secondLine}
        title={pageTitle}
        copyrightYear={'2022'}
      >
        <Seo
          title={pageTitle}
          description="A development agency specializing in Ruby on Rails, Solidus, eCommerce, and React."
          keywords={[
            `Ruby on Rails`,
            `Rails`,
            `React`,
            `Spree`,
            `Solidus`,
            `eCommerce`,
          ]}
        />
        <Layout>
          <TextColumnWrapper>
            <ColumnBody>
              <ColumnTitle>We're developers</ColumnTitle>We provide the
              development chops, no matter what you need. With years of
              experience, we have the skills to move quickly and build
              maintainable systems that run smoothly for years to come.
            </ColumnBody>
            <ColumnHorizontalLine />
            <ColumnBody>
              <ColumnTitle>We're integrated</ColumnTitle>We get close,
              collaborate, and adapt with your team. We identify and work toward
              shared goals, building long-term relationships that deliver
              increasing value over time.{' '}
            </ColumnBody>
            <ColumnHorizontalLine />
            <ColumnBody>
              <ColumnTitle>We're agile</ColumnTitle>We believe in agile
              development as it was originally intended. As we get your project
              moving, we make sure you have visibility into the progress being
              made so we can pivot as needed.{' '}
            </ColumnBody>
          </TextColumnWrapper>
        </Layout>
        <Title>We're value driven</Title>
        <AboutCarousel />
        <Title>It's all dev, all day</Title>
        <TitleGatsbyLink to="/careers">Join the team →</TitleGatsbyLink>
        {windowResizing ? (
          <MatterPlaceholder />
        ) : (
          <MatterComponent images={teamImages} fullheight={true} />
        )}
        <Spacer />
        <Title>We’re contributors</Title>
        <TitleLink href="https://github.com/SuperGoodSoft">
          SuperGoodSoft on Github →
        </TitleLink>
        <AboutSlidingPills />
        <Spacer />
        <Layout>
          <ContactFormWrapper ref={contactFormRef}>
            {contactFormInView ? <ContactForm /> : null}
          </ContactFormWrapper>
        </Layout>
      </HeroPageTemplate>
    </BackgroundWrapper>
  )
}

export default About
